
import React from 'react';
import { BsTwitter, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="https://twitter.com/Error4_100_4" target= "_blank" rel="noreferrer">
      <BsTwitter />
      </a>
    </div>
    <div>
      <a href="https://web.facebook.com/othniel.jean.9" target= "_blank" rel= "noreferrer">
      <FaFacebookF />
      </a>    
    </div>
    <div>
      <a href ="https://www.instagram.com/irreduke/" target="_blank" rel= "noreferrer">
      <BsInstagram />
      </a>    
    </div>
  </div>
);

export default SocialMedia;